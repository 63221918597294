//components/pagination.js
import React from "react"
import { Link } from "gatsby"

export const Pagination = ({ page, totalCount }) => {
  const range = (start, end) => [...Array(end - start + 1)].map((_, i) => start + i)

  const PerPage = 5
  const totalPages = Math.ceil(totalCount / PerPage)

  return (
    <ul className="flex flex-row space-x-2">
      {range(1, totalPages).map((number, index) => (
        <li key={index}>
          <Link to={`/${page}/${number}`}>
            <button className="rounded-full text-white bg-secondry-main hover:opacity-60 w-10 h-10">
              {number}
            </button>
          </Link>
        </li>
      ))}
    </ul>
  )
}
